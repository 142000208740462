$(document).ready(function ($) {
  var _winw = $(window).width();
  // 手机导航
  $('.menuBtn').click(function (event) {
    $(this).toggleClass('open');
    $('.nav').stop().slideToggle();
  });
  var _winw = $(window).width();
  if( _winw > 959 ){
      $('.nav li').hover(function() {
          $(this).find('.sub').stop().slideDown();
          if ($(this).find('.sub').length) {
              $(this).addClass('ok');
              $('.header').addClass('on');
          } else {
              $('.header').removeClass('on');
          }
      }, function() {
          $(this).removeClass('ok');
          $(this).find('.sub').stop().slideUp();
      });
      $('.nav').mouseleave(function(event) {
          if ($('.header').hasClass('on')) {
              $('.header').removeClass('on');
          };
      });
  }else{
      $('.nav .v1').click(function() {
          if ($(this).siblings('.sub').length) {
              $(this).parents('li').siblings('li').find('.sub').stop().slideUp();
              $(this).siblings('.sub').stop().slideToggle();
              return false;
          }
      });
  }
 
  // 返回顶部
  $('body').on('click', '.g-backtop', function (e) {
    e.stopPropagation();
    $("body,html").animate({
        scrollTop: 0
      },
      500);
  });
  // 头部动态加类
  var header = $('.header'),
    headerH = header.height(),
    initScrh = $(window).scrollTop();

  function changeHeader(scrH) {
    if (scrH > headerH) {
      header.addClass('fixed');
    } else {
      header.removeClass('fixed');
    }
  }
  changeHeader(initScrh);
  $(window).on('scroll', function () {
    var _scrH = $(window).scrollTop();
    changeHeader(_scrH);
  });

  
});
