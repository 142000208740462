import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import '@/assets/css/lib.scss';
import '@/assets/css/style.css';
import '@/utils/rem.js';
import '@/utils/filter.js';
Vue.config.productionTip = false

import $ from 'jquery'
import '@/assets/js/lib.js'

import "animate.css"
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'
Vue.prototype.$wow = wow


import utils from '@/utils/utils'
Vue.prototype.$utils = utils;
// Vue.use(ElementUI);
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




