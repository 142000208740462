<template>
  <div id="app">
    <Header />
    <router-view/>
    <div id="ht-preloader" v-show="show">
      <div class="loader clear-loader">
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header.vue";

export default {
  name: "Home",
  components: {
    Header,
  },
  data: () => {
    return {
      show: true
    }
  },

  mounted() {
    setTimeout(() => {
      this.show = false;
    }, 4000)
    // new this.$wow.WOW().init()
    var wow = new this.$wow.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
};
</script>

<style>
:root {
  
  --themeht-typography-body-font-family: Inter;
    --themeht-typography-body-font-weight: 400;
    --themeht-typography-body-font-size: 16px;
    --themeht-typography-body-line-height: 28px;
    --themeht-typography-body-color: #414d5b;
    --themeht-typography-secondary-font-family: Saira;
    --themeht-typography-h1-font-family: Saira;
    --themeht-typography-h1-font-weight: 700;
    --themeht-typography-h1-font-size: 70px;
    --themeht-typography-h1-line-height: 80px;
    --themeht-typography-h1-color: #0f0d25;
    --themeht-typography-h2-font-family: Saira;
    --themeht-typography-h2-font-weight: 700;
    --themeht-typography-h2-font-size: 45px;
    --themeht-typography-h2-line-height: 55px;
    --themeht-typography-h2-color: #0f0d25;
    --themeht-typography-h3-font-family: Saira;
    --themeht-typography-h3-font-weight: 600;
    --themeht-typography-h3-font-size: 30px;
    --themeht-typography-h3-line-height: 40px;
    --themeht-typography-h3-color: #0f0d25;
    --themeht-typography-h4-font-family: Saira;
    --themeht-typography-h4-font-weight: 600;
    --themeht-typography-h4-font-size: 24px;
    --themeht-typography-h4-line-height: 34px;
    --themeht-typography-h4-color: #0f0d25;
    --themeht-typography-h5-font-family: Saira;
    --themeht-typography-h5-font-weight: 600;
    --themeht-typography-h5-font-size: 20px;
    --themeht-typography-h5-line-height: 30px;
    --themeht-typography-h5-color: #0f0d25;
    --themeht-typography-h6-font-family: Saira;
    --themeht-typography-h6-font-weight: 600;
    --themeht-typography-h6-font-size: 18px;
    --themeht-typography-h6-line-height: 28px;
    --themeht-typography-h6-color: #0f0d25;
    --themeht-primary-color: #fff;
    --themeht-secondary-color: #F1503C;
    --themeht-body-color: #414D5B;
    --themeht-text-color: #0F0D25;
    --themeht-white-color: #FFFFFF;
    --themeht-bg-dark-color: #0C0A1F;
    --themeht-bg-light-color: #F3F3F3;
    --themeht-border-light-color: #E3E3E3;
}
#ht-preloader {
  background: var(--themeht-bg-dark-color);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader span {
  animation: loader-rotate 2s infinite;
  height: 50px;
  width: 50px;
}

.loader span:before,
.loader span:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader span:before {
  animation: ball1 2s infinite;
  background-color: var(--themeht-primary-color);
  box-shadow: 30px 0 0 var(--themeht-primary-color);
  margin-bottom: 10px;
}

.loader span:after {
  animation: ball2 2s infinite;
  background-color: var(--themeht-primary-color);
  box-shadow: 30px 0 0 var(--themeht-primary-color);
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--themeht-primary-color);
  }

  50% {
    box-shadow: 0 0 0 var(--themeht-primary-color);
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--themeht-primary-color);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--themeht-primary-color);
  }

  50% {
    box-shadow: 0 0 0 var(--themeht-primary-color);
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--themeht-primary-color);
    margin-top: 0;
  }
}
</style>
