<template>
  <div class="header wow fadeInDown" data-wow-delay="4s">
    <div class="inner">
      <div class="wp">
        <div class="l">
          <router-link to="homecopy" class="logo"><img src="@/assets/images/logo.png" alt="" /> </router-link>
        </div>
        <div class="r">
          <ul class="nav">
            <li :class="pos == 1 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Product </router-link>
                <em class="arr"></em>
                <div class="sub">
                  <router-link to="">Product</router-link>
                  <router-link to="">Product</router-link>
                  <router-link to="">Product</router-link>
                </div>
              </div>
            </li>
            <li :class="pos == 2 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Developers</router-link>
              </div>
            </li>
            <li :class="pos == 3 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Use Case</router-link>
              </div>
            </li>
            <li :class="pos == 4 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Research</router-link>
              </div>
            </li>
            <li :class="pos == 5 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Docs</router-link>
              </div>
            </li>
            <li :class="pos == 6 ? 'act' : ''">
              <div class="v1">
                <router-link to="/" class="t">Company</router-link>
              </div>
            </li>
          </ul>
          <div class="h-btns">
            <router-link to="" class="btn">Comming Soon</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pos"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
/* .header .inner::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
} */
.header.fixed .wp {
  height: 75px;
}
.header.fixed .inner::after {
  opacity: 1;
}
.header.fixed {
  background: rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.header .wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  height: 100px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
}
.header .r {
  display: flex;
  align-items: center;
}
.logo {
  cursor: pointer;
  font-size: 0;
  margin-right: 45px;
}

.logo img {
  display: inline-block;
  vertical-align: middle;
  width: 169px;
}

.nav {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}


.nav li {
  display: inline-block;
  position: relative;
  margin: 0 20px;
}

.nav .v1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: .3s;
}
.nav .v1:hover{
  /* opacity: 0.4; */
}
.nav .arr {
  width: 8px;
  height: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(@/assets/images/arr.png);
  margin-left: 6px;
}

.nav .t {
  font-size: 14px;
  color: #fff;
  line-height: 74px;
  display: block;
  position: relative;
  /* transition: 0.3s; */
}
.nav .t:hover {
  opacity: 0.4;
}

.nav .act .t {
  font-weight: bold;
}
.nav .act .t::after {
  opacity: 1;
}
.sub {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  border-radius: 15px;
  border: 1px solid #1f2327;
  /* background-color: rgba(13, 17, 23, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px); */
  padding: 22px 10px 12px;
  display: none;
  overflow: hidden;
}
.sub::after{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #182130;
  opacity: 0.9;
  /* background-color: rgba(13, 17, 23, 0.7);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px); */
  content: "";
}
.sub a {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 14px;
  transition: 0.3s;
  margin-bottom: 12px;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 10px ;
}
.sub a:hover {
  background-color: #fff;
  color: #333;
}

.h-btns {
  display: flex;
  align-items: center;
  margin-left: 368px;
}
.h-btns .btn {
  width: 160px;
  line-height: 50px;
  background: rgba(61, 61, 61, 0.2);
  box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 42px 42px 42px 42px;
  border: 1px solid #3d3d3d;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
}
.h-btns .btn:hover {
  background-color: #fff;
  color: #000;
}
@media only screen and (max-width: 1366px) {
  .header .wp{
    max-width: 1520px;
  }
  .h-btns{
    margin-left: 100px;
  }
}
</style>
