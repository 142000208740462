// 转为系统时间显示
const formatDate = (_date, _format) => {
  if (!_date) return ''
  if (!_date.getDate) _date = new Date(_date)
  _format = _format || 'yyyy-MM-dd hh:mm:ss'
  const o = {
    'y+': _date.getFullYear(),
    'M+': _date.getMonth() + 1, // 月份
    'd+': _date.getDate(), // 日
    'h+': _date.getHours(), // 小时
    'm+': _date.getMinutes(), // 分
    's+': _date.getSeconds(), // 秒
    'q+': Math.floor((_date.getMonth() + 3) / 3), // 季度
    S: _date.getMilliseconds(), // 毫秒
  }
  if (/(y+)/.test(_format)) {
    _format = _format.replace(
      RegExp.$1,
      `${_date.getFullYear()}`.substr(4 - RegExp.$1.length),
    )
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(_format)) {
      _format = _format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      )
    }
  }

  return _format
}
export default {
  formatDate
}